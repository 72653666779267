import React from 'react'
import styled from 'styled-components';


const Card =styled.div`
width:49%;
height: auto;
padding: 20px;
border-radius: 20px;
background-color: #eee;
`

const Title = styled.h1`
font-size: 2rem;
font-family: 'Raleway';
font-weight: 600;
color: #333;
margin-bottom: 30px;

`

const Text = styled.p`
font-size: 1rem;
font-family: 'Raleway';
color: #333;
`


function PromoCrad2() {
  return (
   <Card>
    <Title>ACHETEZ VOS PNEUS À PRIX DISCOUNT</Title>
    <Text>
        Vous en avez assez d'acheter vos pneus trop chers ? 
        Vous cherchez des pneumatiques bon marché ? Bienvenue sur PNEUSERVICE, 
        le spécialiste de la vente en ligne de pneus à bas prix. 
        PNEUSERVICE est l’expert e-commerce du pneu en Algerie. 
        nous vous garantissons des pneus à prix discount tout au long de l'année !
    </Text>
    <br/>
    <Text>
        Faites jusqu'à 60% d'économies en achetant vos pneus neufs au meilleur prix sur PNEUSERVICE. 
        Nous proposons toutes les tailles de pneumatiques disponibles sur le marché à des prix avantageux.
    </Text>
    <br/>
    <Text>
        trouvez facilement les pneus les plus adaptés à vos besoins. 
        Grâce à notre comparateur, sélectionnez la bonne dimension et faites 
        le choix du modèle de pneus qui vous convient selon votre budget.
    </Text>
    <br/>
    <Text>
        En plus de la livraison gratuite, 
        vous pourrez également profiter de notre réseau de garages partenaires 
        sur Oran et sur plusieur wilaya tres prochainement pour réaliser le montage de vos pneus.
    </Text>
    <br/>
    <Text>
        Sur PNEUSERVICE, leader de la vente online de pneus pas chers, 
        vous retrouverez à coup sûr vos pneus favoris à un prix défiant toute concurrence !
    </Text>
    <br/>
   </Card>
  )
}

export default PromoCrad2