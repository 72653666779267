import React from 'react'
import styled from 'styled-components';

const Card =styled.div`
width: 49%;
height: auto;
padding: 20px;
border-radius: 20px;
background-color: #eee;
`

const Title = styled.h1`
font-size: 2rem;
font-family: 'Raleway';
font-weight: 600;
color: #333;
margin-bottom: 30px;
`

const Text = styled.p`
font-size: 1rem;
font-family: 'Raleway';
color: #333;
`

function PromoCard1() {
  return (
    <Card>
        <Title>DES PNEUS PAS CHERS ET LIVRÉS RAPIDEMENT</Title>
        <Text>
            De nombreux pneus en promo tout au long de l'année ! 
            Profitez des meilleures réductions sur les plus grandes 
            marques de pneus telles que Michelin, Bridgestone, Continental, 
            Dunlop, Goodyear, Hankook, Nokian, Pirelli, Avon, BFGoodrich, Cooper
        </Text>
        <br/>
        <Text>
            Pour connaître le pneu le plus adapté à votre voiture et à vos besoins, 
            consultez nos guides et conseils dédiés aux pneumatiques, 
            nos conseils dédiés à l'automobile. N'hésitez pas également 
            à nous contacter si vous avez la moindre question.
        </Text>
        <br/>
        <Text>
            Que vous soyez l'heureux propriétaire d'un véhicule de tourisme, 
            d'un 4X4 ou SUV, d'un véhicule de collection, d'un véhicule de compétition, 
            d'un poids lourd, d'une moto, PNEUSERVICE est là pour vous proposer les pneus, 
            les plus adaptés à vos besoins.
        </Text>
        <br/>
   </Card>
  )
}

export default PromoCard1