import image1 from '../../assets/promo1.png'
import image2 from '../../assets/promo2.png'

export default [
    {
        title: "Fourth Slide",
        description: "This is the Fourth slider Image of our carousel",
        urls: image1,
      },
      {
        title: "Fifth Slide",
        description: "This is the Fifth slider Image of our carousel",
        urls: image2,
      }
]