import React from 'react';
import styled from 'styled-components';

//components
import Navbar from '../components/Navbar'
import Slider from '../components/Carousel/Slider';
import PromoCard1 from '../components/Cards/PromoCard1';
import PromoCrad2 from '../components/Cards/PromoCrad2';

//images
import image from '../assets/promo1.png'
import auto from '../assets/auto-promo.jpg'
import moto from '../assets/moto-promo1.png'
import poids_lourd from '../assets/yellow-truck-night.jpg'


//styled components
const Title = styled.div`
width: 100%;
height: 10vh;
display: flex;
justify-content: center;
align-items: center;
background: #333;
font-family: 'Raleway';
color: #fff;
`
const PromoContainer = styled.div`
padding: 2%;
`
const FirstRow = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`

const PromoColumn = styled.div`
width:50%;
height: 50vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content:space-between;
padding: 0 0.5% 0 2% ;
`

const PrincipalCard = styled.div`
width: 100%;
height: 100%;
border-radius: 20px;
background: #eee;
display: flex;
justify-content: flex-start;
align-items: flex-start;
position: relative;
`

const ImageAuto = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 20px ;

`
const Overlay = styled.div`
position: absolute;
top: 0;
left: 0;
z-index: 10;
border-radius: 20px;
background: rgba(0, 0, 0, 0.3);
backdrop-filter: blur(3px);
width: 100%;
height: 100%;
`
const SecondaryOverlay = styled.div`
position: absolute;
top: 0;
left: 0;
z-index: 10;
border-radius: 20px;
background: rgba(0, 0, 0, 0.3);
width: 100%;
height: 100%;
`

const TextArea = styled.div`
position: absolute;
top: 0;
left: 0;
z-index: 11;
width: 100%;
height: 100%;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const ImageWrapper =styled.div`
width: 50%;
height: 100%;
position: relative;
`
const TextWrapper = styled.div`
width:50%;
height: 100%;
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
`


const SecondaryTitle = styled.p`
font-family: 'Raleway';
font-size: 3rem;
padding: 0;
margin: 0;
font-weight: bolder;
`

const Button = styled.button`
padding: 10px 20px;
border: none;
border-radius: 10px;
color: #fff;
background: red;
`
const ButtonPd =styled.button`
padding: 5px 20px;
border: none;
border-radius: 10px;
color: #fff;
font-size: 1rem;
background: #F2D22E;
`
const ButtonMoto =styled.button`
padding: 5px 20px;
border: none;
border-radius: 10px;
color: #fff;
font-size: 1rem;
background: #F28907;
`

const PromoRow = styled.div`
width: 100%;
height: 48%;
border-radius: 20px;
background: #333;
display: flex;
justify-content: center;
align-items: center;
position: relative;
`

const InfoContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
padding: 2%;
`


function CentreMentage(){
    return(
        <React.Fragment>
            <Navbar color='#262626'/> 
         
                <Slider/>
                <Title>
                    <h5>Pneus Actuellement en PROMO</h5>
                </Title>
                <PromoContainer>
                    <FirstRow>
                        <PromoColumn>
                            <PrincipalCard>
                                <ImageAuto src={auto}/>
                                <Overlay></Overlay>
                                <TextArea>
                                    <SecondaryTitle>LES PNEUS AUTO</SecondaryTitle>
                                    <h3 style={{color:'#eee'}}>EN PROMO</h3>
                                    <Button>TOUT VOIR</Button>
                                </TextArea>
                            </PrincipalCard>
                        </PromoColumn>
                        <PromoColumn>
                            <PromoRow>
                                <ImageWrapper>
                                    <ImageAuto src={moto}/>
                                    <SecondaryOverlay></SecondaryOverlay>
                                </ImageWrapper>
                                <TextWrapper>
                                    <p style={{color:'#eee', fontSize:'1.5rem', fontFamily:'Raleway', padding:'0', margin:'0'}}>Les pneus poids lourds</p>
                                    <h5 style={{color:'#eee', fontSize:'1rem', fontFamily:'Raleway'}}>en promo</h5>
                                    <ButtonMoto>TOUT VOIR</ButtonMoto>
                                </TextWrapper>
                               
                            </PromoRow>
                            <PromoRow>
                                <ImageWrapper>
                                    <ImageAuto src={poids_lourd}/>
                                    <SecondaryOverlay></SecondaryOverlay>
                                </ImageWrapper>
                                <TextWrapper>
                                    <p style={{color:'#eee', fontSize:'1.5rem', fontFamily:'Raleway', padding:'0', margin:'0'}}>Les pneus poids lourds</p>
                                    <h5 style={{color:'#eee', fontSize:'1rem', fontFamily:'Raleway'}}>en promo</h5>
                                    <ButtonPd>TOUT VOIR</ButtonPd>
                                </TextWrapper>
                            </PromoRow>
                        </PromoColumn>
                    </FirstRow>
                </PromoContainer>
                <InfoContainer>
                    <PromoCard1/>
                    <PromoCrad2/>
                </InfoContainer>
           
        </React.Fragment>
    )
}
export default CentreMentage; 
